import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import AddressSearchInput from "components/common/AddressSearchInput"

import heroBanner from 'images/homepage/hero-frypan.png';

// style
// bespoke styles for this page
// https://www.gatsbyjs.com/docs/how-to/styling/css-modules
import 'styles/pages/homepage.css';

function HomepageHeroBanner (props) {

    return (
        <>
            <LayoutPanel theme="mer-theme--cyan"
                         background="mer-bg--primary-cyan"
                         padding="mer-py-lg"
                         id="homepage-hero-banner">
                <div className="container">
                    <div className="row mer-panel align-items-center justify-content-around">

                        <div className="col-10 col-sm-8 col-md-7 col-lg-6 mer-text--size-lg p-lg-0">
                            <div className="content">
                                <h1>Your local gas supplier.</h1>
                                <h6>Enjoy ongoing value and award-winning local service.</h6>
                                <p>We're your WA born and bred gas provider, bringing best-in-class customer service with better value to our local community.</p>
                            </div>
                            
                            <form className="mer-mt-de mb-0">
                                <fieldset>
                                    <legend>Sign up online in a few easy steps.</legend>
                                    <div className="row">
                                        <div className="col-12 col-lg-10">
                                            <AddressSearchInput searchButtonText="Search"
                                                                showLabel="false" />
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>

                        <div className="mer-panel__images hero-banner col-8 col-sm-6 col-md-5 p-0 mt-3 mt-md-0">
                            <img src={heroBanner} 
                                 className="mer-panel__image"
                                 alt="Frying pan with smiley egg yolk" />
                        </div>
                    </div>
                </div>
            </LayoutPanel>
        </>
    );
}

export default HomepageHeroBanner;